const lookup = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
]
const formatNumberRegex = /\.0+$|(\.[0-9]*[1-9])0+$/

export const formatCompactNumber = (value: string | number, digits = 2) => {
  const num: number = typeof value === 'string' ? +value : value
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(formatNumberRegex, '$1') +
        item.symbol
    : '0'
}
